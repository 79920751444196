export default {
  "Are you sure to generate code?": "Are you sure to generate code?",
  "Yes": "Yes",
  "No": "No",
  "SaveDraftSuccess": "SaveDraftSuccess",
  "SaveGenerateSuccess": "Save generate success",
  "SaveAbolishedSuccess": "SaveAbolishedSuccess",
  "Loading": "Loading",
  "Point Please select categories": "Point Please select categories",
  "Save Point": "Save Point",
  "Point Name": "Point Name",
  "Point Categories": "Point Categories",
  "Point Location": "Point Location",
  "LAT": "LAT",
  "LONG": "LONG",
  "Point Description": "Point Description",
  "Point Action": "Point Action",
  "Required choose at least a category": "Required choose at least a category",
  "6 at most": "6 at most",
  "Are you sure to delete this code": "Are you sure to delete this code",
  "Spot": "Spot",
  "Please input point name in japanese": "Please input point name in japanese",
  "Please input point name in english": "Please input point name in english",
  "Please input point name in chinese": "Please input point name in chinese",
  "Please input point name in korean": "Please input point name in korean",
  "Please input your latitude": "Please input your latitude",
  "Please input your longitude": "Please input your longitude",
  "Import Type": "Import Type",
  "Import Code Label": "Import Code Label",
  "Import": "Import",
  "Enter a location": "Enter a location",
  "Successful": "Successful",
  "Compose Submit": "Compose Submit",
  "days": "days",
  "month": "month",
  "Please pick a date": "Please pick a date",
  "Please select repeat times": "Please select repeat times",
  "There is a problem with the uploaded file. This file will not be uploaded": "There is a problem with the uploaded file. This file will not be uploaded",
  "Company name placeholder": "Company name placeholder",
  "InvalidPhone": "InvalidPhone",
  "Confirmation code is wrong. Please try again.": "Confirmation code is wrong. Please try again.",
  "Project name": "Project name",
  "Project name max length 50": "Project name max length 50",
  "Save Project": "Save Project",
  "Created Project": "Created Project",
  "Project Compose": "Project Compose",
  "Change Project": "Change Project",
  "UpdateProjectSuccess": "UpdateProjectSuccess",
  "Would you like to duplicate this code？": "Would you like to duplicate this code？",
  "DuplicateSuccess": "DuplicateSuccess",
  "Code Compose": "Code Compose",
  "DeletedSuccess": "DeletedSuccess",
  "Switch": "Switch",
  "ViewCell": "ViewCell",
  "De-active": "De-active",
  "Profile": "Profile",
  "Menu Change Password": "Menu Change Password",
  "Email Settings": "Email Settings",
  "Company name Settings": "Company name Settings",
  "Contact phone Settings": "Contact phone Settings",
  "Company address Settings": "Company address Settings",
  "Company URL Settings": "Company URL Settings",
  "Descriptions Settings": "Descriptions Settings",
  "Please input your Company Address!": "Please input your Company Address!",
  "Please input your Company Name!": "Please input your Company Name!",
  "Please input Url format": "Please input Url format",
  "ChangeEmailSuccess": "ChangeEmailSuccess",
  "Please input your old password!": "Please input your old password!",
  "Please input confirm password": "Please input confirm password",
  "Confirm password does not match": "Confirm password does not match",
  "Total amount": "Total amount",
  "Invoice date": "Invoice date",
  "Paid date": "Paid date",
  "Unit price": "Unit price",
  "Quantity": "Quantity",
  "Voice code": "Voice code",
  "Navi code": "Navi code",
  "Mix code": "Mix code",
  "Spot code": "Spot code",
  "Sub total": "Sub total",
  "On Going": "On Going",
  "Paid": "Paid",
  "Unpaid": "Unpaid",
  "Are you sure delete this message": "",
  "This message is deleted": "",
  "Deleted message is fail": ""
};